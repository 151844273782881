<!-- Used in home, displayed demo text input !-->
<template>
  <div class="row h-50 w-100 p-3" style="height:50%">
    <div
        class="mx-auto text-center align-middle my-auto w-75 h-75 p-3 sentence30pt"
        style="width:60%"
    >
      <br/>
      <analysed-sentence
          :sentence="analyzedSentence"
          :showAnalysis="true"
      />
      <br/>
      <v-textarea
          v-model="sentence"
          auto-grow
          class="py-5"
          label="Phrase à analyser"
          outlined
          placeholder="Écrivez votre phrase ici"
          rows="2"
      />
      <v-btn color="white" @click="getAnalyzedSentence"> Analyser</v-btn>
    </div>
  </div>
</template>

<style lang="scss">
.sentence30pt {
  font-size: 30pt;
}
</style>


<script>
import PhoneticAnalysisService from "../../services/phoneticAnalysisService";
import AnalysedSentence from "./AnalysedSentence.vue";

export default {
  components: {AnalysedSentence},
  data() {
    return {
      sentence: "Beau grand arbre qui pleure",
      analyzedSentence: []
    };
  },
  methods: {
    async getAnalyzedSentence() {
      this.analyzedSentence = await PhoneticAnalysisService.getAnalysisAsWords([
        this.sentence
      ]);
    }
  },
  created() {
    this.getAnalyzedSentence();
  }
};
</script>
